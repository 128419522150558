/*
 *   File : payment-status.js
 *   Author : https://evoqins.com
 *   Description : Common payment status modal for mf and goal investment
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Component
import { PrimaryButton } from "../Buttons";

// Asset 
import PaymentSuccess from "../../Assets/Images/success.webp";
import CloseBtn from "../../Assets/Images/close-btn-white.svg";

function PaymentStatusModal(props) {

    const navigate = useNavigate();

    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentDescription, setPaymentDescription] = useState("");
    const invest_type = props.investmentType;

    const _handleOnNavigate = (path) => {

        let navigate_url = props.type == "ORDER_SUCCESS" ? "/sip" : path;

        let order_tab = 0;
        switch (invest_type) {
            case "Fund": {
                order_tab = 1;
                break;
            };
            case "Goal": {
                order_tab = 2;
                break;
            };
            case "Basket": {
                order_tab = 3;
                break;
            };
            default: {
                order_tab = 2;
            }
        };

        navigate(navigate_url, {
            state: {
                "order_tab": order_tab,
            }
        });

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    };

    useEffect(() => {
        switch (props.type) {
            case "PAYMENT_SUCCESS": {
                setPaymentStatus("Payment successful");
                setPaymentDescription("Your order is in the process, it will reflect in your account in T+3 days.");
                break;
            };
            case "ORDER_SUCCESS": {
                setPaymentStatus("Order successful");
                setPaymentDescription("SIP scheduled successfully");
                break;
            };
            case "REDEEM_SUCCESS": {
                setPaymentStatus("Redeem successful");
                setPaymentDescription("Redeem request successfully created");
                break;
            };
            case "PHONE_CHANGE_SUCCESS": {
                setPaymentStatus("Phone number Changed successfully");
                setPaymentDescription("Phone number updated successfully, you will get all notification and important announcement to your updated new phone number");
                break;
            };
            case "ADD_FAMILY_MEMBER_SUCCESS": {
                setPaymentStatus("Family member added successfuLly");
                setPaymentDescription("Lorem Ipsum has been the industry's standard dummy text ever took a galley");
                break;
            };
            default: return;
        };
    }, [props.type]);



    return (
        <div className="modal fade p-0 m-0"
            id="payment-status-modal"
            tabIndex="-1"
            aria-labelledby="PaymentStatusModal"
            data-bs-backdrop="static"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered min-h90vh">
                <div className=" modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow ">
                    <div className="modal-gradient-bg d-flex flex-column align-items-center modal-body p-4 e-border-radius-24">
                        <img src={CloseBtn}
                            draggable={false}
                            className='cursor-pointer float-end align-self-end'
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt='close'
                            onClick={props.onClose}
                        />

                        <img className="img-fluid col-5 mt-4 pt-3"
                            draggable={false}
                            src={PaymentSuccess}
                            alt="payment status image" />
                        <p className="e-font-14-res text-center e-text-ufo-green e-alt-font-poppins e-font-18 e-font-weight-600 mt-4 mb-2">
                            {paymentStatus}
                        </p>
                        <p className="e-font-12-res line-height-20px-res col-sm-10 mx-auto text-center e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-26px text-center mx-auto mb-0 mb-5 ">
                            {paymentDescription}
                        </p>
                        {
                            props.navigate !== false &&
                            <div className="d-flex gap-3 w-100">
                                <PrimaryButton name={"View orders"}
                                    dismiss={"modal"}
                                    className="w-100 padding-12px-tb e-bg-lotion e-font-16 e-text-charleston-green e-border-bright-gray"
                                    onPress={() => _handleOnNavigate("/orders")}
                                />
                                <PrimaryButton name={"Dashboard"}
                                    dismiss={"modal"}
                                    className="padding-12px-tb w-100"
                                    onPress={() => _handleOnNavigate("/")} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(PaymentStatusModal);